import React, {useCallback, useEffect, useState} from 'react';

import ApiErrorBox from '../../../base/components/error/ApiErrorBox';
import IntegrationScreen from '../../../base/components/IntegrationScreen';
import LoadingBox from '../../../base/components/LoadingBox';
import findIntegrationByService from '../../../base/helpers/findIntegrationByService';
import MicrosoftTeamsPresenceIcon from '../../../base/icons/MicrosoftTeamsPresenceIcon';
import {Integration, IntegrationService} from '../../../base/types';
import {MICROSOFT_PRESENCE_AZURE_APP_ID, WIM_CONSOLE} from '../../../env';
import {MICROSOFT_PRESENCE_DESCRIPTION, MICROSOFT_PRESENCE_TITLE} from '../../../texts';
import useConsoleIntegrations from '../../hooks/useConsoleIntegrations';
import useConsoleIntegrationStatusWatching from '../../hooks/useConsoleIntegrationStatusWatching';
import useIntegrationInstallationMutation from '../../hooks/useIntegrationInstallationMutation';
import {MICROSOFT_PRESENCE_OAUTH_URL} from '../constants';
import useMicrosoftPresenceAdminAuthorization from '../hooks/useMicrosoftPresenceAdminAuthorization';
import {MicrosoftPresenceIntegrationPath} from '../types';

export default function MicrosoftIntegrationScreen() {
  const [error, setError] = useState<string>('');
  const {
    isLoading: isCILoading,
    isError: isCIError,
    error: ciError,
    data: {installed = []} = {},
  } = useConsoleIntegrations();

  const [installedServices, setInstalledServices] = useState<Integration[]>(installed);
  const [integration, setIntegration] = useState<Integration | null>(
    findIntegrationByService(installedServices, IntegrationService.MICROSOFT_PRESENCE),
  );
  const {watch, unwatch} = useConsoleIntegrationStatusWatching(setInstalledServices);

  useEffect(() => {
    setInstalledServices(installed);
    watch(installed);
  }, [installed, watch]);

  useEffect(() => {
    setIntegration(findIntegrationByService(installedServices, IntegrationService.MICROSOFT_PRESENCE));
  }, [installedServices]);

  useEffect(() => () => unwatch(), [unwatch]);

  const {isLoading: isInstallationLoading, mutate} = useIntegrationInstallationMutation({
    onSuccess: ({success, message}) => {
      if (!success) {
        setError(message);
      } else {
        watch(installedServices, true);
      }
    },
  });

  const onAuthSuccess = useCallback(
    (code: string) => {
      mutate({service: IntegrationService.MICROSOFT_PRESENCE, data: {code}});
    },
    [mutate],
  );

  const onAuthFailed = useCallback((error: string, description: string) => {
    setError(`${error}\n\n${description}`);
  }, []);

  const {isLoading: isAuthorizationLoading, authorize} = useMicrosoftPresenceAdminAuthorization({
    authorizeUrl: MICROSOFT_PRESENCE_OAUTH_URL,
    clientId: MICROSOFT_PRESENCE_AZURE_APP_ID,
    redirectUri: `${WIM_CONSOLE}${MicrosoftPresenceIntegrationPath.callback}`,
    onSuccess: onAuthSuccess,
    onError: onAuthFailed,
  });

  const onInstallClick = useCallback(() => {
    if (error) {
      setError('');
    }

    authorize();
  }, [authorize, error]);

  if (isCILoading) {
    return <LoadingBox />;
  }

  if (isCIError) {
    return <ApiErrorBox error={ciError} />;
  }

  return (
    <IntegrationScreen
      showErrors
      title={MICROSOFT_PRESENCE_TITLE}
      description={MICROSOFT_PRESENCE_DESCRIPTION}
      icon={<MicrosoftTeamsPresenceIcon width={96} height={96} />}
      integration={integration}
      onInstallClick={onInstallClick}
      isInstallLoading={isAuthorizationLoading || isInstallationLoading}
      error={error}
    />
  );
}
