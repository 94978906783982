import React from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';

import {TextField} from '@mui/material';

import {get} from 'lodash';

import {BotFormData} from '../../../types';

interface FunctionDescriptionFieldProps {
  form: UseFormReturn<BotFormData>;
  path: string;
}

export default function FunctionDescriptionField(props: FunctionDescriptionFieldProps) {
  const {form, path} = props;
  const {control, formState, trigger} = form;
  const {errors, isSubmitting} = formState;
  const error = get(errors, path);

  return (
    <Controller
      control={control}
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <TextField
          multiline
          minRows={2}
          maxRows={4}
          autoComplete="off"
          inputRef={ref}
          name={name}
          size="small"
          required
          fullWidth
          label="Description"
          placeholder="Call this tool to get available time slots"
          inputProps={{maxLength: 1024}}
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={() => {
            trigger(name);
            onBlur();
          }}
          onChange={onChange}
          disabled={isSubmitting}
          value={value || ''}
          error={!!error}
          helperText={error ? error.message || error.type : undefined}
        />
      )}
      name={path as any}
      rules={{required: true}}
    />
  );
}
